<template>
  <div class="app-popup-editor card">
    <fd-form @submit="onSubmit">
      <modal-content wrapperCSS="w-100">
        <modal-header>
          <h3 class="p-3">{{ isEdit ? "Edit" : "Create" }} App Popup</h3>
        </modal-header>
        <modal-body class="p-2">
          <p class="bg-main fg-white mx-1 mb-2 p-2">
            The recommended aspect ratio is <span class="font-bold">3:4</span>
          </p>

          <image-uploader
            class="col-12 px-1 mb-2"
            v-model="form.image"
            label="Image"
            :multiple="false"
            required
            cropper-enabled
            :stencil-props="{
              aspectRatio: 3 / 4
            }"
            @loading="(val) => (isLoading = val)"
            @error="
              (error, imageName) => {
                $reportError(error, 'Upload App Popup');
              }
            "
          ></image-uploader>
          <hr class="my-4" />
          <div class="row">
            <fd-input
              v-model="form.title"
              class="col-12 sm-col-6 md-col-3 px-1 mb-2"
              label="Title"
              name="popupTitle"
              type="text"
              :validators="[validator.required]"
            >
            </fd-input>
            <fd-select
              v-model="form.type"
              class="col-12 sm-col-6 md-col-3 px-1 mb-2"
              label="Popup Action Type"
              :options="popupTypeOptions"
              :optionValue="(val) => val"
              :optionLabel="(val) => val"
              :validators="[validator.required]"
            >
            </fd-select>
          </div>

          <div class="row">
            <!-- Webview URL -->
            <fd-input
              v-if="isWebviewType"
              v-model="form.payload.url"
              class="col-12 sm-col-6 md-col-3 px-1 mb-2"
              label="Webview URL"
              name="purpose"
              type="text"
              :validators="[validator.required, validator.website]"
            >
            </fd-input>
            <!-- Project Select -->
            <div v-if="isProjectType" class="col-12 mt-3 mb-5">
              <h5 class="px-1 mb-2">Project</h5>
              <v-select
                v-model="form.payload.project"
                :options="projects"
                :loading.sync="isProjectLoading"
                label="name"
                class="col-12 sm-col-6 md-col-4 px-1"
                placeholder="Search a Project"
                required
                @search="searchProject"
              >
                <template #selected-option="{ name, mainPhoto }">
                  <div class="d-flex align-items-center">
                    <img
                      class="select-project-thumbnail"
                      :src="$getFileURL(mainPhoto[0])"
                      :alt="name"
                      @error="$setAltPhoto"
                    />
                    <b class="ml-2">{{ name }}</b>
                  </div>
                </template>
                <template #option="{ name, mainPhoto }">
                  <div class="d-flex align-items-center">
                    <img
                      class="select-project-thumbnail"
                      :src="$getFileURL(mainPhoto[0])"
                      :alt="name"
                      @error="$setAltPhoto"
                    />
                    <b class="ml-2">{{ name }}</b>
                  </div>
                </template>
                <template #no-options>
                  This is no available project option.
                </template>
                <template #open-indicator="{ attributes }">
                  <span v-bind="attributes">
                    <i class="px-1 fas fa-caret-down"></i>
                  </span>
                </template>
                <template #list-footer>
                  <InfiniteLoading @infinite="handleInfiniteLoading">
                    <span slot="no-more"></span>
                  </InfiniteLoading>
                </template>
              </v-select>
            </div>
          </div>
        </modal-body>

        <modal-footer class="d-flex justify-content-end p-2">
          <fd-button
            type="submit"
            class="medium main"
            :isLoading="isFormLoading"
            loadingEnabled
            :disabled="isFormLoading"
          >
            {{ isEdit ? "Update" : "Create" }}
          </fd-button>
          <fd-button class="medium ml-2" @click="$emit('cancel')">
            Cancel
          </fd-button>
        </modal-footer>
      </modal-content>
    </fd-form>
  </div>
</template>

<script>
import { appBannerType } from "@/enums";
import { AppBannerModel } from "@/models";
import { project as projectAPI } from "@/api";
import debounce from "lodash/debounce";
import VSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import InfiniteLoading from "vue-infinite-loading";

import {
  required,
  website
} from "@/components/GlobalComponents/FormComponents/Validator/rules";

export default {
  components: {
    VSelect,
    InfiniteLoading,
    ImageUploader: () => import("@/components/GlobalComponents/ImageUploader")
  },
  mixins: [],
  props: {
    isEdit: {
      type: Boolean,
      default: false
    },
    popup: {
      type: Object,
      default: () => ({})
    }
  },
  data: function () {
    return {
      isLoading: false,

      form: {
        title: "",
        image: [],
        type: "",
        payload: {}
      },

      popupTypeOptions: this.$mapJsonToArray(appBannerType, (e) => e),

      isProjectLoading: false,
      projects: [],
      projectsPagination: {
        current_page: 1,
        total_pages: 1
      },
      projectSearch: "",

      validator: {
        required,
        website
      }
    };
  },
  computed: {
    isFormLoading() {
      return this.isLoading || this.isProjectLoading;
    },
    isWebviewType() {
      return this.form.type === appBannerType.WEBVIEW;
    },
    isProjectType() {
      return this.form.type === appBannerType.PROJECT;
    },
    projectHasNextPage() {
      if (this.isProjectType) {
        return (
          this.projectsPagination.current_page <
          this.projectsPagination.total_pages
        );
      }
      return false;
    }
  },
  watch: {
    "form.type": {
      handler(val) {
        switch (val) {
          case appBannerType.WEBVIEW:
            break;
          case appBannerType.PROJECT:
            this.initProjects();
            break;
          default:
            break;
        }
      }
    }
  },
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    if (this.isEdit) {
      this.form = AppBannerModel.getToEdit(this.popup);
      console.log(this.popup);
      this.handleProjectTypeInit();
    }
  },
  methods: {
    async initProjects() {
      try {
        this.isProjectLoading = true;
        this.resetProjectState();
        await this.getProjects();
        this.isProjectLoading = false;
      } catch (error) {
        this.isProjectLoading = false;
      }
    },
    async getProjects(page = 1, q = {}) {
      try {
        let res = await projectAPI.getProjects(q, { page: page, perPage: 5 });
        this.projects.push(...res.data);
        this.projectsPagination = this._.cloneDeep(res.meta.pagination);
      } catch (error) {
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to fetch project data. Please try again later."
        });
      }
    },
    searchProject: debounce(async function (search, loading) {
      try {
        loading(true);
        await this.getProjects(1, { "name[partial]": search });
        loading(false);
      } catch (error) {
        loading(false);
      }
    }, 300),
    resetProjectState() {
      this.projects = [];
      this.projectsPagination = {
        current_page: 1,
        total_pages: 1
      };
    },

    async handleProjectTypeInit() {
      if (this.popup.type === appBannerType.PROJECT) {
        try {
          this.isProjectLoading = true;
          let data = await projectAPI.getProject(this.popup.payload.projectId);
          this.form.payload.project = data;
          this.isProjectLoading = false;
        } catch (error) {
          this.isProjectLoading = false;
          this.$notify({
            group: "alert",
            type: "error",
            title: "Error",
            text: "Failed to fetch project data. Please try again later."
          });
        }
      }
    },

    async handleInfiniteLoading(infScrollState) {
      //
      if (
        this.projectsPagination.current_page <
        this.projectsPagination.total_pages
      ) {
        let nextPage = ++this.projectsPagination.current_page;

        await this.getProjects(nextPage);
        infScrollState.loaded();
      } else {
        infScrollState.loaded();
        infScrollState.complete();
      }
    },

    onSubmit() {
      this.$emit("submit", AppBannerModel.postPayload(this.form));
    }
  }
};
</script>

<style lang="scss">
.app-popup-editor {
  width: 100em;

  .select-project-thumbnail {
    width: 32px;
    height: 32px;
    object-fit: cover;
    border-radius: 5px;
  }
}
</style>
